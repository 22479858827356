import React, { useEffect, ComponentType } from 'react'
import { Layout } from 'antd'
import { useNavigate } from 'react-router'
import { useSnapshot } from 'valtio'
import styled from 'styled-components'
import { SideBar } from '../../organisms'
import { colors } from '../../../theme'
import { authStore } from 'src/store'
import { Loader } from 'src/components/atom'
import { getItemFromLocalStorage } from 'src/utils'
import { SESSION_KEY } from 'src/constants'

const { Sider, Content } = Layout

const StyledLayout = styled(Layout)`
  & .main-content {
    padding: 20px;
    min-height: 280;
    background: rgb(226, 232, 240);
  }
  & .ant-layout-sider {
    background: rgb(15, 23, 42);
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
    & .logo {
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.3);
      height: 72px;
      display: grid;
      place-items: center;
      cursor: pointer;
      img {
        max-width: 100%;
        height: 60px;
      }
    }
    & .ant-menu {
      background: rgb(15, 23, 42);
      border-right: none;
      box-shadow: 9px 1px 8px -12px rgb(0, 0, 0, 0.75);
      height: calc(100vh - 175px);
      & .ant-menu-item {
        color: ${colors.white};
        padding-left: 15px !important;
        & .ant-menu-title-content {
          border-radius: 10px;
          padding: 5px 10px;
          opacity: 0.8;
          & :last-child {
            font-size: 15px !important;
          }
          &:hover {
            background: rgba(255, 255, 255, 0.1);
            opacity: 1;
          }
        }
      }
      & .ant-menu-submenu {
        color: ${colors.darkGrey3};
      }
      & .ant-menu-item-selected {
        background-color: transparent !important;
      }
    }
  }
  & .ant-layout-sider-collapsed {
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }
  & .ant-layout-header {
    position: sticky;
    top: 0;
  }
  @media (max-width: 768px) {
    & .main-content {
      padding: 0px;
    }
    & .ant-layout-sider {
      position: fixed !important;
      top: 0;
      left: 0;
      z-index: 9999;
    }
    & .ant-layout-sider-collapsed {
      max-width: 0px !important;
      min-width: 0px !important;
      width: 0px !important;
    }
  }
`

const PrivateRoute = (AuthComponent: ComponentType) => {
  function PrivateComponent({ children }) {
    const navigate = useNavigate()
    const { loggedIn, tokenFetching } = useSnapshot(authStore)
    const token = getItemFromLocalStorage(SESSION_KEY)

    useEffect(() => {
      if (!loggedIn && !tokenFetching && !token) {
        navigate('/login')
      }
    }, [loggedIn, tokenFetching])

    if (tokenFetching) {
      return <Loader />
    }

    return <>{loggedIn && <> {children} </>} </>
  }

  return class Higher extends React.Component {
    state: {
      collapsed: boolean
    } = {
      collapsed: false,
    }

    componentDidMount() {
      this.setState({
        collapsed: false,
      })
    }

    handleSidebarToggle = () => {
      this.setState((prevState: { collapsed: boolean }) => {
        return {
          collapsed: !prevState.collapsed,
        }
      })
    }

    render() {
      return (
        <PrivateComponent>
          <StyledLayout>
            <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
              <SideBar collapsed={this.state.collapsed} />
            </Sider>
            <Layout className="layout">
              {/* <Header
                onSidebarToggle={this.handleSidebarToggle}
                collapsed={this.state.collapsed}
              /> */}
              <Content className="main-content">
                <AuthComponent {...this.props} />
              </Content>
            </Layout>
          </StyledLayout>
        </PrivateComponent>
      )
    }
  }
}

export { PrivateRoute }
