import { FC } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { DeleteOutlined, EditFilled } from '@ant-design/icons'
import { Button, Divider, Radio, Text, TextField } from 'src/components'
import { colors } from 'src/theme'

const MainWrapper = styled.div`
  padding: 1.5rem 2rem;
  padding-top: 0;
  background: ${colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid ${colors.lightGrey3};
  border-top: none;
  & .apnea-dat {
    display: flex;
    border: 1px solid ${colors.lightGrey2};
    width: fit-content;
    border-right: none;
    & .column {
      border-right: 1px solid ${colors.lightGrey2};
      & .th {
        background: ${colors.lightGrey3};
        padding: 0.5rem 1rem;
        min-width: 160px;
        border-bottom: 1px solid ${colors.lightGrey2};
      }
      & .td {
        padding: 0.5rem 1rem;
      }
    }
  }
`

const HypoxiaWrapper = styled.div`
  display: flex;
  gap: 10rem;
  & .left-side {
    & .content {
      margin-top: 1rem;
      display: flex;
      gap: 6rem;
    }
  }
  & .right-side {
    & .each-calc {
      display: flex;
      margin-bottom: 0.5rem;
    }
  }
`

const StyledTreatmentPlanWrapper = styled.div`
  & .table {
    margin-top: 1rem;
    border: 1px solid ${colors.lightGrey2};
    border-bottom: none;
    & .row {
      display: flex;
      flex: 1;
      border-bottom: 1px solid ${colors.lightGrey2};
      & .th {
        flex: 1;
        padding: 0.75rem 1rem;
        background: ${colors.lightGrey3};
        &:nth-child(1) {
          flex: 0.35;
        }
        &:nth-child(3) {
          flex: 0.15;
        }
      }
      & .td {
        flex: 1;
        padding: 0.75rem 1rem;
        &:nth-child(1) {
          flex: 0.35;
        }
        &:nth-child(3) {
          flex: 0.15;
        }
        & .anticon {
          padding: 7px;
          border: 1px solid ${colors.lightGrey2};
          border-radius: 5px;
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
`

const TREATMENT_PLAN_DEFAULTS = [
  {
    id: 1,
    condition: 'Non diagnostic study',
    plan: 'Proceed to split night polysomnography',
  },
  {
    id: 2,
    condition: 'Mild OSA',
    plan: 'Proceed to emperic auto CPAP or Oral appliance therapy as 1st line',
  },
  {
    id: 3,
    condition: 'Moderate OSA',
    plan: 'Proceed to emperic auto CPAP with Oral appliance therapy as 2nd line',
  },
  {
    id: 4,
    condition: 'Severe OSA',
    plan: 'Proceed to emperic auto CPAP ',
  },
  {
    id: 5,
    condition: 'Any OSA + Severe sleep hypoxia',
    plan: 'Proceed to in lab PAP titration OR emperic auto CPAP and follow up overnight oxiemtry while on PAP',
  },
]

const ButtonWrapper = styled.div`
  float: right;
  margin-top: 1.5rem;
`

const ReportCalculations: FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <MainWrapper>
        <Text
          preset="bodyLargeRegular"
          content="Report defaults are listed below. Please add/edit template items as needed based on your clinical context."
        />

        <Text content="Sleep Apnea Severity:" preset="headingSmall" />
        <Divider height="1rem" />

        <div className="apnea-dat">
          <div className="column">
            <Text content="AHI" preset="bodyLargeSemiBold" className="th" />
            <Text
              content="OSA Severity"
              preset="bodyLargeRegular"
              className="td"
            />
          </div>
          <div className="column">
            <Text content="0-5" preset="bodyLargeSemiBold" className="th" />
            <Text content="NO OSA" preset="bodyLargeRegular" className="td" />
          </div>
          <div className="column">
            <Text content="5-15" preset="bodyLargeSemiBold" className="th" />
            <Text content="Mild" preset="bodyLargeRegular" className="td" />
          </div>
          <div className="column">
            <Text content="15-30" preset="bodyLargeSemiBold" className="th" />
            <Text content="Moderate" preset="bodyLargeRegular" className="td" />
          </div>
          <div className="column">
            <Text content=">30" preset="bodyLargeSemiBold" className="th" />
            <Text content="Severe" preset="bodyLargeRegular" className="td" />
          </div>
        </div>

        <Divider height="2rem" />

        <HypoxiaWrapper>
          <div className="left-side">
            <Text content="Sleep Hypoxia:" preset="headingSmall" />

            <div className="content">
              <div>
                <Text content="Sleep Hypoxia" preset="bodyLargeSemiBold" />
                <Divider height="5px" />
                <Text content="Severity" preset="bodyLargeRegular" />
              </div>
              <div>
                <Text content="Time < 89%" preset="bodyLargeSemiBold" />
                <Divider height="5px" />
                <TextField value="5 mins" disabled />
              </div>
            </div>
          </div>

          <div className="right-side">
            <Text content="AHI calculation defaults:" preset="headingSmall" />
            <Divider height="1rem" />
            <div className="each-calc">
              <Radio />
              <Text content="AHI 3% AASM" />
            </div>
            <div className="each-calc">
              <Radio />
              <Text content="AHI 4% CMS" />
            </div>
            <div className="each-calc">
              <Radio />
              <Text content="AHI 4% CMS only if age > 65" />
            </div>
            <div className="each-calc">
              <Radio />
              <Text content="Display all (ie display AASM in report even if CMS is used)" />
            </div>
          </div>
        </HypoxiaWrapper>
        <Divider height="2rem" />

        <StyledTreatmentPlanWrapper>
          <Text content="Treatment Plan Defaults:" preset="headingSmall" />
          <div className="table">
            <div className="row">
              <div className="th">
                <Text content="Conditions" preset="bodyLargeSemiBold" />
              </div>
              <div className="th">
                <Text content="Plan" preset="bodyLargeSemiBold" />
              </div>
              <div className="th">
                <Text content="Actions" preset="bodyLargeSemiBold" />
              </div>
            </div>

            {TREATMENT_PLAN_DEFAULTS.map(treatmentPlan => (
              <div className="row" key={treatmentPlan.id}>
                <div className="td">
                  <Text
                    content={treatmentPlan.condition}
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td">
                  <Text
                    content={treatmentPlan.plan}
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td">
                  <EditFilled />
                  <DeleteOutlined />
                </div>
              </div>
            ))}
          </div>
        </StyledTreatmentPlanWrapper>
      </MainWrapper>
      <ButtonWrapper className="btns-wrapper">
        <Button
          onClick={() => navigate('/patients')}
          label="Cancel"
          variant="link"
        />
        <Button onClick={() => navigate('/patients')} label="Save" />
      </ButtonWrapper>
    </>
  )
}

export { ReportCalculations }
