import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { PageHeader, PrivateRoute, StaffForm } from '../../components'
import { APP_TITLE, ROLES } from 'src/constants'
import { useUserGroupRole } from 'src/hooks'
import { Result } from 'antd'

const MainContainer = styled.main``

const StaffAddPageComponent = () => {
  const { isReadAuthorized, isCreateAuthorized } = useUserGroupRole(
    ROLES.STAFFS,
  )

  if (!isReadAuthorized || !isCreateAuthorized) {
    return (
      <Result
        status="403"
        title="Error occured"
        subTitle="You are not authorised to view this page"
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Add Staff | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <StaffForm />
      </MainContainer>
    </>
  )
}

export const StaffAddPage = PrivateRoute(StaffAddPageComponent)
