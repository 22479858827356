import { FC, useState } from 'react'
import styled from 'styled-components'
import { Popconfirm, Result, Space, Tag } from 'antd'
import { Helmet } from 'react-helmet-async'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnapshot } from 'valtio'
import {
  APP_TITLE,
  ERROR_MESSAGES,
  QUERY_KEYS,
  ROLES,
  SUPER_ADMIN,
} from 'src/constants'
import { authStore, modalStore } from 'src/store'
import { useClinics, useOrganisations } from 'src/queries'
import { clinicService } from 'src/services'
import { useUserGroupRole } from 'src/hooks'
import {
  Button,
  Divider,
  PageHeader,
  PrivateRoute,
  Select,
  Switch,
  Table,
  Text,
} from 'src/components'

const MainContainer = styled.main`
  & .page-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    & .buttons-wrapper {
      display: flex;
      gap: 0.5rem;
    }
  }
`

const ClinicsPageComponent: FC = () => {
  const [organisationId, setOrganisationId] = useState('')
  const queryClient = useQueryClient()
  const { userGroup } = useSnapshot(authStore)
  const { isReadAuthorized } = useUserGroupRole(ROLES.CLINICS)
  const { data: organisationsData } = useOrganisations({
    enabled: userGroup.slug === SUPER_ADMIN,
  })
  const { isLoading: clinicsLoading, data: clinics } = useClinics({
    enabled: true,
    organizationId: organisationId,
  })

  const enableClinic = useMutation({
    mutationKey: ['enableClinic'],
    mutationFn: clinicService.enableClinic,
    onSuccess: res => {
      toast.success(res?.data?.message || 'Clinic enabled successfully')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_CLINIC],
      })
    },
    onError: (err: { message: string }) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const disableClinic = useMutation({
    mutationKey: ['disableClinic'],
    mutationFn: clinicService.disableClinic,
    onSuccess: res => {
      toast.success(res?.data?.message || 'Clinic disabled successfully')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_CLINIC],
      })
    },
    onError: (err: { message: string }) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const handleClinicStatus = (disabled: boolean, data: { id: string }) => {
    disabled
      ? enableClinic.mutate({ ...data })
      : disableClinic.mutate({ ...data })
  }

  const clinicsTableHead = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Organisation',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: code => {
        return <Tag color={'green'}>{code}</Tag>
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return (
          <span>
            {createdAt ? format(createdAt, 'yyyy/MM/dd hh:mm a') : ''}
          </span>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (disabled, data) => {
        return (
          <Space size="middle">
            <Popconfirm
              title={`Are you sure you want to disable this clinic ?`}
              onConfirm={() =>
                handleClinicStatus(disabled, {
                  id: data.id!,
                })
              }
              okText="Yes"
              cancelText="No"
              placement="topLeft"
            >
              <Switch
                checked={!disabled}
                title={disabled ? 'Enable clinic' : 'Disable clinic'}
              />
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  if (!isReadAuthorized) {
    return (
      <Result
        status="403"
        title="Error occured"
        subTitle="You are not authorised to view this page"
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Clinics | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <section>
          <PageHeader />
          <div className="page-top">
            <Text content="Clinics" preset="headingSmall" />
            <div className="buttons-wrapper">
              {userGroup.slug === SUPER_ADMIN && (
                <Select
                  width="200px"
                  placeholder="Organisation"
                  onChange={e => setOrganisationId(e)}
                  options={organisationsData?.map(organisation => {
                    return {
                      key: organisation.id,
                      name: organisation.name,
                      value: organisation.value,
                    }
                  })}
                />
              )}
              <Button
                label="Add Clinic"
                onClick={() => modalStore.addClinic.open()}
              />
            </div>
          </div>

          <Divider height="10px" />
          <Table
            dataSource={clinics || []}
            columns={clinicsTableHead}
            pagination={false}
            loading={clinicsLoading}
          />
        </section>
      </MainContainer>
    </>
  )
}

export const ClinicsPage = PrivateRoute(ClinicsPageComponent)
