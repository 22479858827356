import { useCallback, useEffect, useRef } from 'react'
import { useSnapshot } from 'valtio'
import { toast } from 'react-toastify'
import { ERROR_MESSAGES, SESSION_KEY } from 'src/constants'
import { staffsService } from 'src/services'
import { authStore } from 'src/store'
import { getItemFromLocalStorage, removeItemFromLocalStorage } from 'src/utils'

const useClientAuthentication = () => {
  const token = getItemFromLocalStorage(SESSION_KEY)
  const { dbUser, loggedIn, tokenFetching } = useSnapshot(authStore)
  const isMounted = useRef(false)

  const fetchLoggedInUser = useCallback(async () => {
    try {
      authStore.setTokenFetching(true)
      const data = await staffsService.fetchDBUser()
      if (data) {
        authStore.setDbUser(data?.staff)
        authStore.setUserGroup(data?.userGroup)
        authStore.setUserGroupPermissions(data?.userGroupPermissions)
        authStore.setLoggedIn()
      }
    } catch (err: any) {
      if (err?.status?.code === 401) {
        toast.error(ERROR_MESSAGES.SESSION_EXPIRED)
      } else {
        toast.error(ERROR_MESSAGES.ERROR_OCCURED)
      }
    } finally {
      authStore.setTokenFetching(false)
    }
  }, [])

  useEffect(() => {
    if (isMounted.current) return

    if (token) {
      try {
        if (!dbUser?.id && !loggedIn && !tokenFetching) {
          fetchLoggedInUser()
        }
      } catch (error) {
        authStore.setLogout()
        removeItemFromLocalStorage(SESSION_KEY)
      }
    } else {
      authStore.setLogout()
    }
    return () => {
      isMounted.current = true
    }
  }, [token])

  return { token }
}

export { useClientAuthentication }
