import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { Result } from 'antd'
import styled from 'styled-components'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
  Button,
  Divider,
  PageHeader,
  PrivateRoute,
  Text,
} from '../../components'
import { APP_TITLE, ROLES } from '../../constants'
import { useUserGroupRole } from 'src/hooks'
import { colors } from 'src/theme'
import { usePatients } from 'src/queries'

const MainContainer = styled.section`
  & .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  & .calendar-wrapper {
    background-color: ${colors.white};
    padding: 1.5rem;
    border-radius: 8px;
  }
`

const SchedulingPageComponent = () => {
  const navigate = useNavigate()
  const { isReadAuthorized } = useUserGroupRole(ROLES.SCHEDULING)
  const { isCreateAuthorized } = useUserGroupRole(ROLES.PATIENTS)

  const { data } = usePatients({ enabled: true })

  if (!isReadAuthorized) {
    return (
      <Result
        status="403"
        title="Error occured"
        subTitle="You are not authorised to view this page"
      />
    )
  }

  const events = data?.patients.map(patient => {
    return {
      title: `${patient.firstName} ${patient.lastName}`,
      start: patient.appointment.startAt,
    }
  })

  // a custom render function
  function renderEventContent(eventInfo) {
    return (
      <>
        {/* <Text content={eventInfo.timeText} preset="bodyMediumSemibold" /> */}
        {/* <Divider width="6px" /> */}
        <Text content={eventInfo.event.title} />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Schedule | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <div className="sub-header">
          <Text content="Scheduling" preset="headingMedium" />
          {isCreateAuthorized && (
            <Button
              label="Schedule Patient"
              onClick={() => navigate('/scheduling/patients')}
            />
          )}
        </div>
        <Divider height="1rem" />
        <div className="calendar-wrapper">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            weekends={false}
            events={events}
            eventContent={renderEventContent}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            buttonText={{
              today: 'Today',
              month: 'Month',
              week: 'Week',
              day: 'Day',
            }}
            eventTimeFormat={{
              hour12: true,
              hour: 'numeric',
              minute: '2-digit',
              meridiem: 'short',
            }}
            views={{
              timeGridFourDay: {
                type: 'timeGrid',
                duration: { days: 4 },
                buttonText: '4 day',
              },
            }}
          />
        </div>
      </MainContainer>
    </>
  )
}

export const SchedulingPage = PrivateRoute(SchedulingPageComponent)
