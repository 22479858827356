import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { Result } from 'antd'
import { APP_TITLE, ERROR_MESSAGES, QUERY_KEYS, ROLES } from 'src/constants'
import { colors } from 'src/theme'
import { useUserGroupRole } from 'src/hooks'
import { usePatients, useStaffs } from 'src/queries'
import { IPatientSchedule } from '../../interfaces'
import { patientService } from '../../services'
import { VALIDATION_SCHEMA, calculateBMI } from '../../utils'
import {
  Button,
  Select,
  Text,
  TextField,
  PageHeader,
  CheckBox,
  PrivateRoute,
  Divider,
  DatePicker,
} from '../../components'

const MainContainer = styled.section`
  & .page-header {
    display: flex;
    align-items: center;
    & .icon-back {
      margin-right: 0.5rem;
    }
  }
  & form {
    margin-top: 2rem;
    & .main-wrapper {
      background: ${colors.white};
      padding: 1.5rem 2rem;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
    }
    & .field-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      & > div {
        flex: 30%;
      }
      & .field-bmi {
        display: flex;
        gap: 1rem;
        align-items: flex-end;
      }
    }
    & .fields-scheduling {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      & > div {
        width: 32%;
      }
    }
    & .btn-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      margin-top: 1rem;
    }
  }
`

interface ISchedulepage {
  id?: boolean
}

const SchedulePatientPageComponent: FC<ISchedulepage> = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isReadAuthorized } = useUserGroupRole(ROLES.SCHEDULING)
  const { data: patientsData } = usePatients({ enabled: true })
  const { data: staffsData } = useStaffs({ enabled: true })
  const [bmi, setBmi] = useState<number | undefined>(undefined)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm<IPatientSchedule & { hasId: boolean }>({
    defaultValues: {
      hasId: false,
      id: undefined,
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      height: undefined,
      weight: undefined,
      phone: '',
      startAt: '',
      duration: 1,
      physicianId: undefined,
      technicianId: undefined,
      consent: false,
      location: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(VALIDATION_SCHEMA.patientUnionSchema),
  })

  const patientID = watch('id')

  const addPatient = useMutation({
    mutationKey: [patientID ? 'addPatient' : 'addPatientAppointment'],
    mutationFn: patientID
      ? patientService.schedulePatientAppointment
      : patientService.addPatient,
    onSuccess: () => {
      toast.success('Patient created successfully')
      navigate('/patients')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_PATIENTS],
      })
      reset()
    },

    onError: (err: Error) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  useEffect(() => {
    if (patientID) {
      clearErrors()
      reset({ id: patientID })
      setValue('hasId', true)
    } else {
      setValue('hasId', false)
    }
  }, [patientID])

  const handlePatientSubmit = async (
    payload: IPatientSchedule & { hasId?: boolean },
  ) => {
    try {
      const savePayload = {
        ...payload,
      }

      if (!patientID) {
        //Convert feet to cms
        savePayload.height = Number(savePayload.height) * 30.48
        //Convert Lbs to gms
        savePayload.weight = Number(savePayload.weight) * 453.592
      }

      delete savePayload.hasId
      addPatient.mutate(savePayload)
    } catch (err) {
      toast.error(ERROR_MESSAGES.ERROR_OCCURED)
    }
  }

  const handleDateChange = (key: 'startAt' | 'dob', date) => {
    if (!date) {
      !getValues(key) && setValue(key, '')
      return
    }
    setValue(key, format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'"))
  }

  const handleBmiCalculate = () => {
    if (getValues('height') && getValues('weight')) {
      const value = calculateBMI(getValues('weight'), getValues('height'))
      setBmi(value)
    }
  }

  if (!isReadAuthorized) {
    return (
      <Result
        status="403"
        title="Error occured"
        subTitle="You are not authorised to view this page"
      />
    )
  }

  const staffOptions = staffsData?.staffs?.map(staff => {
    return {
      name: staff.lastName,
      value: staff.id,
    }
  })

  return (
    <>
      <Helmet>
        <title>Schedule Patient | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <section>
          <div className="page-header">
            <ArrowLeftOutlined
              onClick={() => navigate('/patients')}
              className="icon-back"
            />
            <Text content="Schedule Patient" preset="headingMedium" />
          </div>
          <form className="form">
            <div className="main-wrapper">
              <div>
                <Text content="Choose current patient" preset="headingSmall" />
                <Divider height="20px" />
                <Controller
                  name="id"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <Select
                      value={value}
                      placeholder="Choose Patient"
                      width="300px"
                      onChange={onChange}
                      onBlur={onBlur}
                      options={patientsData?.patients?.map(patient => {
                        return {
                          key: patient?.id,
                          name: `${patient?.firstName} ${patient?.lastName}`,
                          value: patient?.id,
                        }
                      })}
                      error={error?.message}
                    />
                  )}
                />
              </div>
              <Divider height="20px" />
              <div>
                <Text content="Add a new patient" preset="headingSmall" />
                <Divider height="20px" />
              </div>
              <div className="field-wrapper">
                <Controller
                  name="firstName"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      placeholder="Enter first name"
                      label="First Name"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      placeholder="Enter last name"
                      label="Last Name"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />

                <Controller
                  name="dob"
                  control={control}
                  render={({
                    field: { onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <DatePicker
                        placeholder="Enter dob"
                        label="Date of birth"
                        required
                        onChange={date => handleDateChange('dob', date)}
                        onBlur={onBlur}
                        value={value}
                        error={error?.message}
                        className="form-fields"
                      />
                    </>
                  )}
                />

                <Controller
                  name="height"
                  control={control}
                  render={({
                    field: { value, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="number"
                      placeholder="Enter height (feet)"
                      label="Height (feet)"
                      value={Number(value)}
                      onChange={e => setValue('height', Number(e.target.value))}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="weight"
                  control={control}
                  render={({
                    field: { value, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="number"
                      placeholder="Enter weight (Lbs)"
                      label="Weight (Lbs)"
                      value={Number(value)}
                      onChange={e => setValue('weight', Number(e.target.value))}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <div className="field-bmi">
                  <Button
                    label="Calculate BMI"
                    width="200px"
                    onClick={handleBmiCalculate}
                  />
                  <TextField
                    placeholder="Enter BMI"
                    label="BMI"
                    value={bmi}
                    disabled
                  />
                </div>

                <Controller
                  name="phone"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      placeholder="Enter phone"
                      label="Phone"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      placeholder="Enter email"
                      label="Email"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />

                <Controller
                  name="location"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      placeholder="Enter location"
                      label="Location"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />

                <div>
                  <div style={{ display: 'flex' }}>
                    <CheckBox
                      onChange={e => {
                        setValue('consent', e?.target?.checked as boolean)
                      }}
                    />
                    <Divider width="12px" />
                    <Text content="Consents to text" />
                  </div>
                  {errors?.consent && (
                    <span className="error">{errors?.consent?.message}</span>
                  )}
                </div>
              </div>
              <Divider height="2rem" />

              <Text content="Scheduling Details" preset="headingSmall" />
              <Divider height="2rem" />

              <div className="fields-scheduling">
                <Controller
                  name="startAt"
                  control={control}
                  render={({
                    field: { onBlur, value },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      required
                      placeholder="Choose start day"
                      label="Start Date"
                      onChange={date => handleDateChange('startAt', date)}
                      value={value}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="duration"
                  control={control}
                  render={({
                    field: { value, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      required
                      type="number"
                      placeholder="Choose duration"
                      label="Number of Nights"
                      value={Number(value)}
                      onChange={e =>
                        setValue('duration', Number(e.target.value))
                      }
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="physicianId"
                  control={control}
                  render={({
                    field: { value, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      placeholder="Select physician"
                      label="Physician"
                      value={value}
                      options={staffOptions}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
                <Controller
                  name="technicianId"
                  control={control}
                  render={({
                    field: { value, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      placeholder="Select technician"
                      label="Technician"
                      value={value}
                      options={staffOptions}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={error?.message}
                      className="form-fields"
                    />
                  )}
                />
              </div>
            </div>
            <div className="btn-wrapper">
              <Button label="Cancel" variant="link" />
              <Button
                label="Schedule"
                onClick={handleSubmit(handlePatientSubmit)}
                variant="basic"
                background="transparent"
              />
              <Button
                label="Schedule And Print Instructions"
                onClick={handleSubmit(handlePatientSubmit)}
                variant="primary"
              />
            </div>
          </form>
        </section>
      </MainContainer>
    </>
  )
}

export const SchedulePatientPage = PrivateRoute(SchedulePatientPageComponent)
