import {
  ContactSection,
  FeatureSection,
  Header,
  LandinBanner,
  TechnologySection,
} from '../../components'

const HomePage = () => {
  return (
    <>
      <Header />
      <LandinBanner />
      <FeatureSection />
      <TechnologySection />
      <ContactSection />
    </>
  )
}

export { HomePage }
