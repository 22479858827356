import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Result } from 'antd'
import { PageHeader, PrivateRoute, StaffForm } from 'src/components'
import { APP_TITLE, ROLES } from 'src/constants'
import { useUserGroupRole } from 'src/hooks'

const StaffEditPageComponent: FC = () => {
  const { isReadAuthorized, isUpdateAuthorized } = useUserGroupRole(
    ROLES.STAFFS,
  )

  if (!isReadAuthorized || !isUpdateAuthorized) {
    return (
      <Result
        status="403"
        title="Error occured"
        subTitle="You are not authorised to view this page"
      />
    )
  }
  return (
    <>
      <Helmet>
        <title>Edit User | {APP_TITLE}</title>
      </Helmet>
      <main>
        <PageHeader />
        <StaffForm isEditMode />
      </main>
    </>
  )
}

export const StaffEditPage = PrivateRoute(StaffEditPageComponent)
