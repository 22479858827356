import { IClinic } from './clinic'
import { IOrganization } from './organisation'

export interface IPatient {
  id?: string
  firstName: string
  lastName: string
  dob: string
  height: number
  weight: number
  phone: string
  email: string
  createdAt?: string
  location?: string
  organizationId?: string
  clinicId?: string
  physicianId?: string
  technicianId?: string
  username?: string
  organization?: IOrganization
  clinic?: IClinic
  appointment?: IAppointment
  consent?: boolean
}

export enum AppointmentStatus {
  upload_pending = 'upload_pending',
  queued = 'queued',
  scored = 'scored',
  interpreted = 'interpreted',
  billed = 'billed',
}

export interface IAppointment {
  id: string
  startAt: string
  duration: number
  status: AppointmentStatus
}

export type IPatientSchedule = IPatient & IAppointment

export interface IPatientReport {
  id: string
}
