import { FC } from 'react'
import styled from 'styled-components'
import { Popconfirm, Result, Space, Tag } from 'antd'
import { Helmet } from 'react-helmet-async'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { APP_TITLE, ERROR_MESSAGES, QUERY_KEYS, ROLES } from 'src/constants'
import { modalStore } from 'src/store'
import { useClinics, useOrganisations } from 'src/queries'
import { clinicService, organisationService } from 'src/services'
import { useUserGroupRole } from 'src/hooks'
import {
  Button,
  Divider,
  PageHeader,
  PrivateRoute,
  Switch,
  Table,
  Text,
} from 'src/components'

const MainContainer = styled.main`
  & .page-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    & .buttons-wrapper {
      display: flex;
      gap: 0.5rem;
    }
  }
`

const OrganisationPageComponent: FC = () => {
  const queryClient = useQueryClient()
  const { isLoading, data: organisations } = useOrganisations({ enabled: true })
  const { isLoading: clinicsLoading, data: clinics } = useClinics({
    enabled: true,
  })
  const { isReadAuthorized } = useUserGroupRole(ROLES.SUPER_ADMIN)

  const enableOrganisation = useMutation({
    mutationKey: ['enableOrg'],
    mutationFn: organisationService.enableOrganisation,
    onSuccess: res => {
      toast.success(res?.data?.message || 'Organization enabled successfully')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ORGANISATIONS],
      })
    },
    onError: (err: { message: string }) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const disableOrganisation = useMutation({
    mutationKey: ['disableOrg'],
    mutationFn: organisationService.disableOrganisation,
    onSuccess: res => {
      toast.success(res?.data?.message || 'Organization disabled successfully')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_ORGANISATIONS],
      })
    },
    onError: (err: { message: string }) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const enableClinic = useMutation({
    mutationKey: ['enableClinic'],
    mutationFn: clinicService.enableClinic,
    onSuccess: res => {
      toast.success(res?.data?.message || 'Clinic enabled successfully')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_CLINIC],
      })
    },
    onError: (err: { message: string }) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const disableClinic = useMutation({
    mutationKey: ['disableClinic'],
    mutationFn: clinicService.disableClinic,
    onSuccess: res => {
      toast.success(res?.data?.message || 'Clinic disabled successfully')
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_CLINIC],
      })
    },
    onError: (err: { message: string }) => {
      toast.error(err?.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const handleOrganisationStatus = (
    disabled: boolean,
    data: { id: string },
  ) => {
    disabled
      ? enableOrganisation.mutate({ ...data })
      : disableOrganisation.mutate({ ...data })
  }

  const handleClinicStatus = (disabled: boolean, data: { id: string }) => {
    disabled
      ? enableClinic.mutate({ ...data })
      : disableClinic.mutate({ ...data })
  }

  const clinicsTableHead = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Organisation',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: code => {
        return <Tag color={'green'}>{code}</Tag>
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return (
          <span>
            {createdAt ? format(createdAt, 'yyyy/MM/dd hh:mm a') : ''}
          </span>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (disabled, data) => {
        return (
          <Space size="middle">
            <Popconfirm
              title={`Are you sure you want to disable this clinic ?`}
              onConfirm={() =>
                handleClinicStatus(disabled, {
                  id: data.id!,
                })
              }
              okText="Yes"
              cancelText="No"
              placement="topLeft"
            >
              <Switch
                checked={!disabled}
                title={disabled ? 'Enable clinic' : 'Disable clinic'}
              />
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const tableHead = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    // {
    //   title: 'Code',
    //   dataIndex: 'code',
    //   key: 'code',
    //   render: code => {
    //     return <Tag color={'green'}>{code}</Tag>
    //   },
    // },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{format(createdAt, 'yyyy/MM/dd hh:mm a')}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (disabled, data) => {
        return (
          <Space size="middle">
            <Popconfirm
              title={`Are you sure you want to disable this organisation ?`}
              onConfirm={() =>
                handleOrganisationStatus(disabled, {
                  id: data.id!,
                })
              }
              okText="Yes"
              cancelText="No"
              placement="topLeft"
            >
              <Switch
                checked={!disabled}
                title={
                  disabled ? 'Enable organization' : 'Disable organization'
                }
              />
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  if (!isReadAuthorized) {
    return (
      <Result
        status="404"
        title="Error occured"
        subTitle="The page you are looking for doesnot exist"
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Organization | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <section>
          <PageHeader />
          <div className="page-top">
            <Text content="Organisations" preset="headingSmall" />
            <div className="buttons-wrapper">
              <Button
                label="Add Organisation"
                onClick={() => modalStore.addOrganisation.open()}
              />
              <Button
                label="Add Clinic"
                onClick={() => modalStore.addClinic.open()}
              />
            </div>
          </div>

          <Divider height="10px" />
          <Table
            dataSource={organisations || []}
            columns={tableHead}
            pagination={false}
            loading={isLoading}
          />

          <Divider height="30px" />
          <Text content="Clinics" preset="headingSmall" />
          <Divider height="10px" />
          <Table
            dataSource={clinics || []}
            columns={clinicsTableHead}
            pagination={false}
            loading={clinicsLoading}
          />
        </section>
      </MainContainer>
    </>
  )
}

export const OrganisationPage = PrivateRoute(OrganisationPageComponent)
