import { proxy } from 'valtio'
import { IModalStore } from '../interfaces'

/**
 * Global Modal Store
 */
const modalStore = proxy<IModalStore>({
  changePassword: {
    shouldOpen: false,
    staffId: '',
    open() {
      this.shouldOpen = true
    },
    close() {
      this.shouldOpen = false
    },
    setStaffId(id) {
      this.staffId = id
    },
  },
  addRole: {
    shouldOpen: false,
    open() {
      this.shouldOpen = true
    },
    close() {
      this.shouldOpen = false
    },
  },
  addUserGroup: {
    shouldOpen: false,
    open() {
      this.shouldOpen = true
    },
    close() {
      this.shouldOpen = false
    },
  },
  addOrganisation: {
    shouldOpen: false,
    open() {
      this.shouldOpen = true
    },
    close() {
      this.shouldOpen = false
    },
  },
  addClinic: {
    shouldOpen: false,
    open() {
      this.shouldOpen = true
    },
    close() {
      this.shouldOpen = false
    },
  },
  roleAssignment: {
    shouldOpen: false,
    userGroupId: '',
    open() {
      this.shouldOpen = true
    },
    close() {
      this.shouldOpen = false
    },
    setUserGroupId(id) {
      this.userGroupId = id
    },
  },
  logOut: {
    shouldOpen: false,
    open() {
      this.shouldOpen = true
    },
    close() {
      this.shouldOpen = false
    },
  },
})

export { modalStore }
