import { QueryFunction } from '@tanstack/react-query'
import { IPatientReport } from 'src/interfaces'
import { API } from 'src/lib'

const fetchPatientReport: QueryFunction<IPatientReport> = async id => {
  const { data } = await API.get(`/patient-report/${id}`)
  return data
}

export const patientReportService = {
  fetchPatientReport,
}
