import { FC } from 'react'
import styled from 'styled-components'
import { Avatar } from 'antd'
import { toast } from 'react-toastify'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { useMutation } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import { Helmet } from 'react-helmet-async'
import { APP_TITLE, SUCCESS_MESSAGES, SESSION_KEY } from '../../constants'
import { VALIDATION_SCHEMA, saveItemToLocalStorage } from '../../utils'
import { DBUser } from '../../interfaces'
import { staffsService } from '../../services'
import { Button, PublicRoute, TextField } from 'src/components'
import { authStore } from 'src/store'

const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f1f5f9;
  overflow-x: hidden;
  & .image-wrapper {
    height: 120px;
    width: 120px;
    margin-top: -91px;
    margin-bottom: 10px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid rgb(226, 232, 240);
    display: grid;
    place-items: center;
    & .ant-avatar {
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain !important;
      }
    }
  }
`

const FormContainer = styled.div`
  width: 360px;
  border: 1px solid rgb(226, 232, 240);
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  align-items: center;
  padding: 1rem 1.25rem 2.5rem 1.2rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  & form {
    width: 100%;
    margin-top: 16px;
    & .login-field {
      margin-bottom: 15px;
    }
    & .btn-login {
      margin-left: 50%;
      width: 100%;
      transform: translateX(-50%);
      height: 40px;
    }
  }
`

const ButtonWrap = styled(Button)`
  margin-top: 10px;
  & button {
    height: 60px;
  }
`

export type LoginDto = z.infer<typeof VALIDATION_SCHEMA.login>

const LoginPages: FC = () => {
  const initialValues: Partial<DBUser> = {
    username: '',
    password: '',
  }

  const { mutate: login, isPending } = useMutation({
    mutationFn: staffsService.login,
    onSuccess: async data => {
      saveItemToLocalStorage(SESSION_KEY, data?.token)
      authStore.setUserGroup(data?.userGroup)
      authStore.setUserGroupPermissions(data?.userGroupPermissions)
      toast.success(SUCCESS_MESSAGES.LOGIN_SUCCESS)
      authStore.setLoggedIn()
    },
    onError: ({ message }: { message: string }) => {
      toast.error(message)
    },
  })

  const { control, handleSubmit } = useForm<LoginDto>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(VALIDATION_SCHEMA.login),
  })

  const onSubmit = (data: LoginDto) => {
    login({ ...data })
  }

  return (
    <>
      <Helmet>
        <title>Login | {APP_TITLE}</title>
      </Helmet>
      <Main>
        <FormContainer>
          <div className="image-wrapper">
            <Avatar src="/assets/imgs/logo.webp" size={110} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="username"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  type="text"
                  name="username"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="login-field"
                  placeholder="Username"
                  autoFocus
                  prefix={
                    <img
                      src="/assets/icons/email.svg"
                      height={20}
                      width={20}
                      alt="fd"
                    />
                  }
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  type="password"
                  name="password"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="login-field"
                  placeholder="Password"
                  prefix={
                    <img
                      src="/assets/icons/lock.svg"
                      height={18}
                      width={20}
                      alt="fd"
                    />
                  }
                />
              )}
            />
            <ButtonWrap
              htmlType="submit"
              className="btn-login"
              loading={isPending}
              disabled={isPending}
            >
              Log In
            </ButtonWrap>
          </form>
        </FormContainer>
      </Main>
    </>
  )
}

const LoginPage = PublicRoute(LoginPages)

export { LoginPage }
