import {
  MutationFunction,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IUserGroup } from 'src/interfaces'
import { API } from 'src/lib'

const fetchUserGroup: QueryFunction<IUserGroup[]> = async ({ queryKey }) => {
  const keyword = queryKey?.[3] as QueryKey
  const page = queryKey?.[1] as QueryKey
  const pageSize = queryKey?.[2] as QueryKey

  const { data } = await API.get(`/user-group/all`, {
    params: {
      page,
      pageSize,
      keyword,
    },
  })
  return data
}

const addUserGroup: MutationFunction<
  AxiosResponse,
  IUserGroup
> = async reqPayload => {
  return API.post('/user-group', reqPayload)
}

const deleteUserGroup: MutationFunction<AxiosResponse, string> = id => {
  return API.delete(`/user-group/${id}`)
}

const fetchUserGroupPermissions = async id => {
  const { data } = await API.get(`/permission/user-group/${id}`)
  return data
}

const addUserGroupPermission: MutationFunction<
  AxiosResponse,
  any
> = async reqPayload => {
  return API.put(
    `/permission/user-group/${reqPayload?.userGroupId}`,
    reqPayload,
  )
}

export const userGroupService = {
  addUserGroup,
  fetchUserGroup,
  deleteUserGroup,
  fetchUserGroupPermissions,
  addUserGroupPermission,
}
