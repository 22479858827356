import { CSSProperties, FC, createRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import jsPDF from 'jspdf'
import { useUserGroupRole } from 'src/hooks'
import { APP_TITLE, ROLES } from 'src/constants'
import { colors } from 'src/theme'
import {
  Text,
  PageHeader,
  PrivateRoute,
  Button,
  TextField,
  Radio,
  Divider,
} from 'src/components'
import { usePatientReport } from 'src/queries'

const MainContainer = styled.section`
  & .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .left {
      display: flex;
      & .username {
        display: flex;
      }
    }
    & .icon-back {
      margin-right: 0.6rem;
    }
  }
  & .container {
    background: ${colors.white};
    border-radius: 0.5rem;
    box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
    padding: 1.5rem 2rem;
  }

  & .btn-container {
    float: right;
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
  }
`

interface ISchedulepage {
  id?: boolean
}

const getTableDataStyle: CSSProperties = {
  display: 'flex',
  borderBottom: `1px solid ${colors.lightGrey2}`,
  padding: '1rem',
}

const getlabelStyle: CSSProperties = {
  width: '260px',
}

const getValueStyle: CSSProperties = {
  width: '180px',
}

const getTemplateStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
}

const PatientDetailsPageComponent: FC<ISchedulepage> = () => {
  const navigate = useNavigate()
  const { isReadAuthorized } = useUserGroupRole(ROLES.PATIENTS)
  const pdfRef = createRef<HTMLDivElement>()

  const { state } = useLocation()

  const { data } = usePatientReport({
    enabled: isReadAuthorized,
    id: state?.id,
  })

  // eslint-disable-next-line no-console
  console.log('data===>', data)

  const downloadPDF = () => {
    const input = pdfRef.current
    if (!input) return

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4',
      putOnlyUsedFonts: true,
      compress: true,
    })

    pdf
      .html(pdfRef.current, {
        callback: function (doc) {
          doc.save()
        },
        x: 1,
        y: 1,
        // width: 210,
        // windowWidth: 210,
        margin: 5,
        html2canvas: { scale: 0.45 },
      })
      .save('report.pdf')
  }

  if (!isReadAuthorized) {
    return (
      <Result
        status="403"
        title="Error occured"
        subTitle="You are not authorised to view this page"
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Add Patient | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <section>
          <div className="page-header">
            <div className="left">
              <div className="username">
                <ArrowLeftOutlined
                  onClick={() => navigate('/patients')}
                  className="icon-back"
                />
                <Text content={'Lili Mouse'} preset="headingSmall" />
              </div>
              <Divider width="20px" />
              <Text content={'Study 1 of 2'} preset="headingSmall" />
            </div>
            <Button label="Raw Data" />
          </div>
          <Divider height="20px" />

          <div className="container" ref={pdfRef}>
            <div
              className="date-wrapper"
              style={{ display: 'flex', gap: '6rem' }}
            >
              <div className="each-detail">
                <Text
                  className="label"
                  content="DOB"
                  preset="bodyLargeSemiBold"
                />
                <Text
                  className="value"
                  content="11/03/1986"
                  preset="bodyMediumRegular"
                />
              </div>
              <div className="each-detail">
                <Text
                  className="label"
                  content="Assigned Physician"
                  preset="bodyLargeSemiBold"
                />
                <Text
                  className="value"
                  content="Dr. House"
                  preset="bodyMediumRegular"
                />
              </div>
              <div className="each-detail">
                <Text
                  className="label"
                  content="Date of test"
                  preset="bodyLargeSemiBold"
                  style={getlabelStyle}
                />
                <Text
                  className="value"
                  content="01/02/2023 - 1/03/2024(3 night)"
                  preset="bodyMediumRegular"
                  style={getValueStyle}
                />
              </div>
            </div>
            <Divider height="1.5rem" />
            <div className="diagnosis">
              <Text
                className="label"
                content="Diagonosis"
                preset="bodyLargeSemiBold"
              />
              <Text
                className="value"
                content="Moderate Obstructive Sleep Apnea (G47.33) Sleep Hypoxia is not
                prolonged"
                preset="bodyMediumRegular"
              />
            </div>
            <Divider height="1.5rem" />
            <div>
              <Text
                className="label"
                content="Indications"
                preset="bodyLargeSemiBold"
                style={getlabelStyle}
              />
              <div className="">
                <TextField placeholder="Enter indication" width="400px" />
              </div>
            </div>

            <Divider height="2rem" />
            <div>
              <Text content="Study Data" preset="headingSmall" />
            </div>

            <Divider height="10px" />
            <div className="table" style={{ display: 'flex' }}>
              <div
                className="column"
                style={{
                  border: `1px solid ${colors.lightGrey2}`,
                  borderBottom: 'none',
                  borderRight: 'none',
                }}
              >
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Lights out"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="10:00PM"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Total recording time"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text content="235mins" className="value" />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="AHI 4%"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="24"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Apnea count"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="300"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Obstructive Apnea count"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="2111"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="ODI 4%"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="8"
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Time<89%"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="211"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="HR min"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="20"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="HR mean"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="20"
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
              </div>
              <div
                className="column"
                style={{ border: `1px solid ${colors.lightGrey2}` }}
              >
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Lights on"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="6:00PM"
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Total sleep time"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="200mins"
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="AHI 3%"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="210"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Hypopnea Count"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="20"
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Central Apnea Count"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content="22"
                    className="value"
                    preset="bodyLargeRegular"
                    style={getValueStyle}
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="ODI 3%"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content=""
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="Time < 88%"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content=""
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
                <div className="td" style={getTableDataStyle}>
                  <Text
                    content="HR max"
                    className="label"
                    preset="bodyLargeSemiBold"
                    style={getlabelStyle}
                  />
                  <Text
                    content=""
                    className="value"
                    preset="bodyLargeRegular"
                  />
                </div>
              </div>
            </div>

            <Divider height="2rem" />

            <div
              className="templates-wrapper"
              style={{
                display: 'flex',
                gap: '2.5rem',
              }}
            >
              <div className="impression">
                <Text content="Impresssion" preset="bodyLargeSemiBold" />
                <Divider height="5px" />
                <TextField width="300px" placeholder="Enter indication" />
              </div>

              <div
                className="templates"
                style={{
                  display: 'flex',
                  marginTop: '1.6rem',
                  flexDirection: 'row',
                  gap: '4rem',
                }}
              >
                <div>
                  <div className="template" style={getTemplateStyle}>
                    <Radio />
                    <Text content="Template 1" />
                  </div>
                  <div className="template" style={getTemplateStyle}>
                    <Radio />
                    <Text content="Template 2" />
                  </div>
                  <div className="template" style={getTemplateStyle}>
                    <Radio />
                    <Text content="Template 3" />
                  </div>
                </div>

                <div>
                  <div className="template" style={getTemplateStyle}>
                    <Radio />
                    <Text content="Template 4" />
                  </div>
                  <div className="template" style={getTemplateStyle}>
                    <Radio />
                    <Text content="Template 5" />
                  </div>
                  <div className="template" style={getTemplateStyle}>
                    <Radio />
                    <Text content="Template 6" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn-container">
            <Button label="Cancel" variant="link" />
            <Button
              label="Download PDF"
              variant="basic"
              onClick={downloadPDF}
            />
            <Button label="Sign" />
            <Button label="Sign And Next" />
          </div>
        </section>
      </MainContainer>
    </>
  )
}

export const PatientDetailsPage = PrivateRoute(PatientDetailsPageComponent)
