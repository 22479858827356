import { FC } from 'react'
import styled from 'styled-components'
import { Popconfirm, Result, Space, Tag } from 'antd'
import { Helmet } from 'react-helmet-async'
import { format } from 'date-fns'
import { DeleteOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  Button,
  Divider,
  PageHeader,
  PrivateRoute,
  Table,
  Text,
} from 'src/components'
import {
  APP_TITLE,
  ERROR_MESSAGES,
  QUERY_KEYS,
  ROLES,
  SUPER_ADMIN,
} from 'src/constants'
import { modalStore } from 'src/store'
import { useRoles, useUserGroup } from 'src/queries'
import { colors } from 'src/theme'
import { userGroupService } from 'src/services'
import { useUserGroupRole } from 'src/hooks'

const MainContainer = styled.main`
  & .page-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    & .buttons-wrapper {
      display: flex;
      gap: 0.5rem;
    }
  }
  & .btn--delete {
    color: ${colors.red};
  }
`

const AdminPageComponent: FC = () => {
  const queryClient = useQueryClient()
  const { data: userGroupData, isLoading } = useUserGroup({ enabled: true })
  const { data: rolesData, isLoading: isRolesLoading } = useRoles({
    enabled: true,
  })
  const { isReadAuthorized } = useUserGroupRole(ROLES.SUPER_ADMIN)

  // const roleDeleteMutation = useMutation({
  //   mutationKey: ['deleteRole'],
  //   mutationFn: roleService?.deleteRole,
  //   onSuccess: () => {
  //     toast.success('Roles archived successfully')
  //     queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FETCH_ROLES] })
  //   },
  //   onError: (err: AxiosError) => {
  //     toast.error(err.message || ERROR_MESSAGES.ERROR_OCCURED)
  //   },
  // })

  const userGroupDeleteMutation = useMutation({
    mutationKey: ['userGroupDelete'],
    mutationFn: userGroupService?.deleteUserGroup,
    onSuccess: () => {
      toast.success('User group deleted successfully')
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FETCH_USER_GROUP] })
    },
    onError: (err: AxiosError) => {
      toast.error(err.message || ERROR_MESSAGES.ERROR_OCCURED)
    },
  })

  const tableHead = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      render: slug => {
        return <Tag color={'green'}>{slug}</Tag>
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{format(createdAt, 'yyyy/MM/dd hh:mm a')}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (_, __) => {
        return (
          <Space size="middle">
            {/* <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this role ?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => roleDeleteMutation.mutate(id)}
            >
              <DeleteOutlined className="btn--delete" title="Delete Role" />
            </Popconfirm> */}
          </Space>
        )
      },
    },
  ]

  const userGroupTableHead = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      render: slug => {
        return <Tag color="cyan">{slug}</Tag>
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{format(createdAt, 'yyyy/MM/dd hh:mm a')}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id, data) => {
        return (
          <Space size="middle">
            <>
              <span
                onClick={() => {
                  modalStore.roleAssignment.setUserGroupId(id)
                  modalStore.roleAssignment.open()
                }}
              >
                <img
                  src="/assets/icons/employee.png"
                  height={20}
                  width={20}
                  title="Assign role"
                  style={{ cursor: 'pointer' }}
                />
              </span>

              {data?.slug != SUPER_ADMIN && data?.staff?.length === 0 && (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to delete this user group ?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => userGroupDeleteMutation.mutate(id)}
                >
                  <DeleteOutlined
                    className="btn--delete"
                    title="Delete user group"
                  />
                </Popconfirm>
              )}
            </>
          </Space>
        )
      },
    },
  ]

  if (!isReadAuthorized) {
    return (
      <Result
        status="404"
        title="Error occured"
        subTitle="The page you are looking for doesnot exist"
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Admin | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <section>
          <PageHeader />
          <div className="page-top">
            <Text content="Roles" preset="headingSmall" />
            <div className="buttons-wrapper">
              <Button
                label="Add Role"
                onClick={() => modalStore.addRole.open()}
              />
              <Button
                label="Add User Group"
                onClick={() => modalStore.addUserGroup.open()}
              />
            </div>
          </div>

          <Divider height="10px" />
          <Table
            dataSource={rolesData || []}
            columns={tableHead}
            pagination={false}
            loading={isRolesLoading}
          />

          <Divider height="30px" />
          <Text content="User Group" preset="headingSmall" />
          <Divider height="10px" />
          <Table
            dataSource={userGroupData || []}
            columns={userGroupTableHead}
            pagination={false}
            loading={isLoading}
          />
        </section>
      </MainContainer>
    </>
  )
}

export const AdminPage = PrivateRoute(AdminPageComponent)
