import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { Button } from '../../atom'

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1248px;
  margin: auto;
  padding: 20px 10px;

  @media (width > 768px) {
    padding: 20px 0px;
    & .left-side {
      img {
        width: 180px;
      }
    }
  }
`

const Header = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <div className="left-side">
        <img
          src="/assets/imgs/logo.webp"
          alt="logo"
          width="100px"
          height="auto"
        />
      </div>
      <div className="right-side">
        <Button onClick={() => navigate('/login')}>Sign in</Button>
      </div>
    </Wrapper>
  )
}

export { Header }
