/**
 *
 * @param weightInPounds
 * @param heightFeet
 * @param heightInches
 * @returns
 */
export function calculateBMI(weightInPounds, heightFeet) {
  // Convert height from feet to inches
  const totalHeightInInches = heightFeet * 12

  // Convert weight from pounds to kilograms
  const weightInKgs = weightInPounds * 0.453592

  // Convert height from inches to meters
  const heightInMeters = totalHeightInInches * 0.0254

  const BMI = weightInKgs / (heightInMeters * heightInMeters)

  return Number(BMI.toFixed(2))
}
