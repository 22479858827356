import React, { useEffect, ComponentType } from 'react'
import { useNavigate } from 'react-router'
import { Loader } from 'src/components/atom'
import { authStore } from 'src/store'
import { useSnapshot } from 'valtio'

const PublicRoute = (PublicComponent: ComponentType) => {
  function RestrictedComponent({ children }) {
    const { loggedIn, dbUser, userGroupPermissions } = useSnapshot(authStore)
    const navigate = useNavigate()

    useEffect(() => {
      if (loggedIn) {
        const hasPatient = !!userGroupPermissions?.find(
          permission => permission.role?.slug === 'patients',
        )?.id
        const currentRoute = userGroupPermissions?.[0].role?.slug

        if (hasPatient) {
          navigate('/patients')
        } else if (currentRoute) {
          navigate(`/${currentRoute}`)
        } else {
          navigate('/organisations')
        }
      } else {
        authStore.setLogout()
      }
    }, [loggedIn, dbUser])

    if (loggedIn) {
      return <Loader size="large" height="100vh" />
    }

    return <>{!loggedIn && children}</>
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <PublicComponent {...this.props} />
        </RestrictedComponent>
      )
    }
  }
}

export { PublicRoute }
