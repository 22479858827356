import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { DatePicker as AntDatePicker, ConfigProvider } from 'antd'
import { format as dateFormat } from 'date-fns'
import styled from 'styled-components'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import type { DatePickerProps } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import { colors } from 'src/theme'

const CustomDatePicker = AntDatePicker.generatePicker<Date>(
  dateFnsGenerateConfig,
)

const { RangePicker } = CustomDatePicker

interface IDatePickerProps {
  value?: any
  format?: string
  onChange?: any
  onOpenChange?: (val: boolean) => void
  size?: 'large' | 'small' | 'middle'
  bordered?: boolean
  className?: string
  name?: string
  label?: string
  range?: boolean
  showTime?: boolean
  suffixIcon?: ReactNode
  error?: string | boolean
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  id?: string
  required?: boolean
  separator?: string
  placeholder?: string
  allowClear?: boolean
  defaultValue?: any
  disabledDate?: (currentDate) => boolean
  inputReadOnly?: boolean
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined
}

const MainWrapper = styled.div`
  & .ant-picker {
    width: 100%;
    height: 40px;
    border-color: ${colors.grey};
  }
  & .error {
    font-size: 12px;
    color: ${colors.red};
    margin-top: 2px;
    margin-left: 2px;
  }
`

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
  & .label {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  & .required {
    color: ${colors.red};
  }
`

const DatePicker: FC<IDatePickerProps> = props => {
  const {
    value,
    onChange,
    format = 'yyyy/MM/dd',
    size,
    className,
    name,
    label,
    required,
    showTime,
    placeholder,
    allowClear,
    id,
    range,
    error,
    inputReadOnly = true,
    onOpenChange,
    picker,
    ...rest
  } = props

  const [currentDate, setCurrentDate] = useState<any>()
  const [rangeDate, setRangeDate] = useState('')

  useEffect(() => {
    if (currentDate) {
      onChange && onChange(currentDate?.format(format))
    }
  }, [currentDate])

  useEffect(() => {
    if (rangeDate) {
      onChange && onChange(rangeDate)
    }
  }, [rangeDate])

  const handleDateChange = useCallback(date => {
    if (range) {
      setRangeDate(date)
      return
    }
    setCurrentDate(date)
  }, [])

  return (
    <MainWrapper>
      {label && (
        <LabelContainer>
          <div className="label">{label}</div>
          {required && <div className="required">*</div>}
        </LabelContainer>
      )}
      <ConfigProvider>
        {range ? (
          <RangePicker
            className={className}
            size={size || 'middle'}
            format={format}
            showTime={showTime}
            name={name}
            suffixIcon={<CalendarOutlined />}
            value={value && dateFormat(value, 'yyyy/MM/dd')}
            id={id}
            onOpenChange={onOpenChange}
            onChange={handleDateChange}
            allowClear={allowClear}
            style={{ width: '300px' }}
            inputReadOnly={inputReadOnly}
          />
        ) : (
          <CustomDatePicker
            className={className}
            size={size || 'middle'}
            value={value && dateFormat(value, 'yyyy/MM/dd')}
            format={format}
            showTime={showTime}
            onChange={onChange}
            placeholder={placeholder || dateFormat(new Date(), 'yyyy/MM/dd')}
            name={name}
            suffixIcon={<CalendarOutlined />}
            allowClear={allowClear}
            picker={picker}
            {...rest}
          />
        )}
      </ConfigProvider>
      {error != '' && <div className="error">{error}</div>}
    </MainWrapper>
  )
}

export type { DatePickerProps }

export { DatePicker }
