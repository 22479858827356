import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'src/constants'
import { AppointmentStatus, IAppointment, IPatient } from 'src/interfaces'
import { patientReportService, patientService } from 'src/services'

/**
 * usePatients
 *
 * @param enabled => query enable/disabled boolean
 * @param searchQuery => search string
 * @returns useQuery response
 */
export const usePatients = ({
  enabled,
  searchQuery,
  pageSize,
  page,
  clinicId,
  status,
}: {
  enabled: boolean
  searchQuery?: any
  archived?: boolean
  pageSize?: number
  page?: number
  clinicId?: string
  status?: AppointmentStatus | ''
}) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.FETCH_PATIENTS,
      page,
      pageSize,
      searchQuery,
      clinicId,
      status,
    ],
    queryFn: patientService.fetchPatients,
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response => {
      return {
        patients: response?.patients?.map((patient: IPatient) => {
          return {
            key: patient?.id,
            id: patient?.id,
            email: patient?.email,
            firstName: patient?.firstName,
            location: patient?.location,
            lastName: patient?.lastName,
            username: patient?.username,
            createdAt: patient?.createdAt,
            clinic: patient?.clinic,
            organization: patient?.organization,
            duration: patient?.appointment?.[0]?.duration,
            appointment: patient?.appointment?.[0] as IAppointment,
          }
        }),
        count: response?.count,
      }
    },
  })
}

/**
 * usePatientReport
 *
 * @param enabled => query enable/disabled boolean
 * @returns useQuery response
 */
export const usePatientReport = ({
  enabled,
  id,
}: {
  enabled: boolean
  id: any
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_PATIENT_REPORT, id],
    queryFn: () => patientReportService.fetchPatientReport(id),
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
  })
}
