import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'src/constants'
import { IClinic } from 'src/interfaces'
import { clinicService } from 'src/services'

/**
 * useCreatorsWithAiCompanion
 *
 * @param enabled => query enable/disabled boolean
 * @param searchQuery => search string
 * @returns useQuery response
 */
const useClinics = ({
  enabled,
  searchQuery,
  organizationId,
}: {
  enabled: boolean
  searchQuery?: any
  organizationId?: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_CLINIC, 1, 12, searchQuery, organizationId],
    queryFn: clinicService.fetchClinics,
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response =>
      response?.map((clinic: IClinic) => {
        return {
          key: clinic?.id,
          id: clinic?.id,
          email: clinic?.email,
          code: clinic?.code,
          value: clinic?.id,
          name: clinic?.name,
          phoneNumber: clinic?.phoneNumber,
          disabled: clinic?.disabled,
          location: clinic?.location,
          createdAt: clinic?.createdAt,
          organizationName: clinic?.organization?.name,
        }
      }),
  })
}

export { useClinics }
