import { FC } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { Result, Tabs } from 'antd'
import { APP_TITLE, ERROR_MESSAGES, ROLES } from 'src/constants'
import { useUserGroupRole } from 'src/hooks'
import { colors } from 'src/theme'
import {
  Divider,
  FreeFormTemplate,
  PageHeader,
  PrivateRoute,
  ReportCalculations,
  Text,
} from '../../components'

const TABS_KEYS = {
  REPORT_CALCULATIONS: 'report_calculations',
  FREE_FORM_TEMPLATE: 'free_form_template',
}

const MainContainer = styled.main`
  & .btn--delete {
    color: ${colors.red};
  }
  & section {
    & .ant-tabs-nav {
      padding: 1.5rem 2rem;
      background: ${colors.white};
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border: 1px solid ${colors.lightGrey3};
      border-bottom: none;
      margin-bottom: 0;
      &:before {
        border-bottom: none;
      }
      & .ant-tabs-tab-btn {
        font-weight: 500;
      }
    }
  }
`

const items = [
  {
    label: 'REPORT CALCULATIONS',
    key: TABS_KEYS.REPORT_CALCULATIONS,
    children: <ReportCalculations />,
  },
  {
    label: 'FREE FORM TEMPLATE',
    key: TABS_KEYS.FREE_FORM_TEMPLATE,
    children: <FreeFormTemplate />,
  },
]

const PhysicianSettingsComponent: FC = () => {
  const { isReadAuthorized } = useUserGroupRole(ROLES.STAFFS)

  if (!isReadAuthorized) {
    return (
      <Result
        status="403"
        title={ERROR_MESSAGES.ERROR_OCCURED}
        subTitle={ERROR_MESSAGES.UNAUTHORIZED_USER}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Physician Settings | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <PageHeader />
        <div className="page-header">
          <Text content="Physician Settings" preset="headingSmall" />
        </div>
        <Divider height="2rem" />
        <section>
          <Tabs items={items} />
        </section>
        <Divider height="20px" />
      </MainContainer>
    </>
  )
}

export const PhysicianSettingsPage = PrivateRoute(PhysicianSettingsComponent)
