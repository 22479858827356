import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { useState } from 'react'
import { Result, Space, Tabs, Tag } from 'antd'
import { FileTextOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
import {
  Button,
  PageHeader,
  Pagination,
  PrivateRoute,
  Select,
  Table,
  TextField,
} from '../../components'
import { APP_TITLE, ROLES } from '../../constants'
import { useClinics, usePatients } from 'src/queries'
import { useDebounce, useUserGroupRole } from 'src/hooks'
import { AppointmentStatus } from 'src/interfaces'

const MainContainer = styled.main`
  & .ant-tabs-nav {
    & .ant-tabs-tab-btn {
      font-weight: 500;
    }
  }
`

const OperationWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
`

const PAGE_SIZE = 12

const PatientsPageComponent = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [clinicId, setClinicId] = useState('')
  const debouncedSearch = useDebounce(searchQuery, 400)
  const { isReadAuthorized } = useUserGroupRole(ROLES.PATIENTS)
  const [status, setStatus] = useState<AppointmentStatus | ''>('')
  const { isReadAuthorized: isClinicsReadAuthorized } = useUserGroupRole(
    ROLES.CLINICS,
  )

  const { data: clinicsData } = useClinics({ enabled: isClinicsReadAuthorized })

  const { data: patientsData, isLoading } = usePatients({
    enabled: true,
    searchQuery: debouncedSearch,
    pageSize: PAGE_SIZE,
    page: currentPage,
    clinicId,
    status,
  })

  const tableHead = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, data) => {
        return (
          <span>
            {data?.firstName} {data?.lastName}
          </span>
        )
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'usernmae',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: location => {
        return location ? <span>{location}</span> : '-'
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: email => {
        return email ? <span>{email}</span> : '-'
      },
    },

    {
      title: 'Status',
      dataIndex: 'appointment',
      key: 'appointment',
      render: appointment => {
        return appointment ? (
          <Tag color={appointment?.status === 'billed' ? 'green' : 'gold'}>
            {appointment?.status}
          </Tag>
        ) : (
          '-'
        )
      },
    },
    {
      title: 'Last study date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => {
        return <span>{format(createdAt, 'yyyy/MM/dd hh:mm a')}</span>
      },
    },
    {
      title: 'STUDY DURATION, HRS',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'AHI',
      dataIndex: 'ahi',
      key: 'ahi',
    },
    {
      title: 'DAYS PENDING',
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: 'TECH NAME',
      dataIndex: 'appointment',
      key: 'appointment',
      render: appointment => appointment?.technician?.firstName,
    },
    {
      title: 'ASSIGNED PHYSICIAN',
      dataIndex: 'appointment',
      key: 'appointment',
      render: appointment => appointment?.physician?.firstName,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id, _) => {
        return (
          <Space size="middle">
            <FileTextOutlined
              title="Patient book"
              onClick={() =>
                navigate('/patients/details', {
                  state: {
                    id: id,
                  },
                })
              }
            />
          </Space>
        )
      },
    },
  ]

  const items = [
    {
      label: `ALL`,
      key: 'all',
      children: (
        <Table
          dataSource={patientsData?.patients || []}
          columns={tableHead}
          pagination={false}
        />
      ),
    },
    {
      label: `UPLOAD PENDING`,
      key: AppointmentStatus.upload_pending,
      children: (
        <Table
          dataSource={patientsData?.patients || []}
          columns={tableHead}
          pagination={false}
        />
      ),
    },
    {
      label: `SCORED`,
      key: AppointmentStatus.scored,
      children: (
        <Table
          dataSource={patientsData?.patients || []}
          columns={tableHead}
          pagination={false}
        />
      ),
    },
    {
      label: `INTERPRETED`,
      key: AppointmentStatus.interpreted,
      children: (
        <Table
          dataSource={patientsData?.patients || []}
          columns={tableHead}
          pagination={false}
          loading={isLoading}
        />
      ),
    },
    {
      label: `BILLED`,
      key: AppointmentStatus.billed,
      children: (
        <Table
          dataSource={patientsData?.patients || []}
          columns={tableHead}
          pagination={false}
          loading={isLoading}
        />
      ),
    },
  ]

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && navigate('/staffs', { state: { page: 1 } })
    currentPage !== 1 && setCurrentPage(1)
    setSearchQuery(e.target.value)
  }

  const operations = (
    <OperationWrapper>
      <TextField
        placeholder="Search"
        type="search"
        value={searchQuery}
        onChange={handleQueryChange}
      />
      {isClinicsReadAuthorized && (
        <Select
          width="200px"
          placeholder="Select"
          options={clinicsData}
          onChange={e => setClinicId(e)}
        />
      )}
      <Button label="Button" />
    </OperationWrapper>
  )

  if (!isReadAuthorized) {
    return (
      <Result
        status="403"
        title="Error occured"
        subTitle="You are not authorised to view this page"
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>Patients | {APP_TITLE}</title>
      </Helmet>
      <MainContainer>
        <section>
          <PageHeader />
          <div className="table-wrapper">
            <Tabs
              tabBarExtraContent={operations}
              items={items}
              onChange={e => {
                if (e === 'all') {
                  return setStatus('')
                }
                setStatus(e as any)
              }}
            />
            <Pagination
              current={+currentPage}
              hideOnSinglePage
              total={patientsData?.count || 0}
              pageSize={PAGE_SIZE}
              onChange={page => {
                setCurrentPage(page)
                navigate('/patients', { state: { page } })
              }}
              showSizeChanger={false}
            />
          </div>
        </section>
      </MainContainer>
    </>
  )
}

export const PatientsPage = PrivateRoute(PatientsPageComponent)
