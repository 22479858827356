import { z } from 'zod'
import { REGEX } from '../constants'

const login = z.object({
  username: z
    .string()
    .regex(REGEX.EMAIL_OR_USERNAME, 'Please exclude any special characters')
    .min(1, "Username can't be empty"),
  password: z.string().min(1, "Password can't be empty"),
})

const userValidationSchema = z.object({
  username: z
    .string()
    .min(1, "Username can't be empty")
    .max(50, 'Characters limit exceeded'),
  email: z
    .string()
    .regex(REGEX.EMAIL_OR_USERNAME, 'Please exclude any special characters')
    .min(1, "Email can't be empty")
    .max(50, 'Characters limit exceeded'),
  firstName: z
    .string()
    .min(1, "Firstname can't be empty")
    .max(50, 'Characters limit exceeded'),
  lastName: z
    .string()
    .min(1, "Lastname can't be empty")
    .max(50, 'Characters limit exceeded'),
  password: z
    .string()
    .min(5, 'Password must contain at least 6 characters')
    .max(50, 'Characters limit exceeded'),
  confirmPassword: z
    .string()
    .min(5, 'Password must contain at least 6 characters')
    .max(50, 'Characters limit exceeded'),
  userGroupId: z
    .string()
    .min(1, 'Required')
    .max(50, 'Characters limit reached'),
  organizationId: z.string().max(50, 'Characters limit reached'),
  clinicId: z.string().min(1, 'Required').max(50, 'Characters limit reached'),
})

const changePassword = z
  .object({
    newPassword: z
      .string()
      .min(6, 'Password must contain at least 6 characters.'),
    verifyPassword: z
      .string()
      .min(6, 'Password must contain at least 6 characters.'),
  })
  .refine(data => data.newPassword === data.verifyPassword, {
    path: ['verifyPassword'],
    message: 'Password doesnot match',
  })

const addRole = z.object({
  name: z
    .string()
    .min(4, 'Name must contain at least 4 characters.')
    .max(30, 'Characters limit exceeded'),
  slug: z
    .string()
    .min(4, 'Key must contain at least 4 characters.')
    .max(30, 'Characters limit exceeded'),
})

const addOrganisation = z.object({
  name: z
    .string()
    .min(3, 'Name must contain at least 3 characters.')
    .max(50, 'Characters limit exceeded'),
  email: z
    .string()
    .min(3, 'Email must contain at least 3 characters.')
    .email('Invalid email format')
    .max(50, 'Characters limit exceeded'),
  phoneNumber: z
    .string()
    .min(0)
    .max(30, 'Characters limit exceeded')
    .regex(REGEX.PHONE_NUMBER, 'Invalid phone number format')
    .optional()
    .or(z.literal('')),
  location: z.string().max(50, 'Characters limit exceeded'),
  // code: z
  //   .string()
  //   .min(2, 'Code must contain at least 2 characters.')
  //   .max(20, 'Characters limit exceeded'),
})

const clinic = z.object({
  name: z
    .string()
    .min(3, 'Name must contain at least 3 characters.')
    .max(50, 'Characters limit exceeded'),
  email: z
    .string()
    .min(4, 'Email must contain at least 4 characters.')
    .email('Invalid email address')
    .max(50, 'Characters limit exceeded'),
  phoneNumber: z
    .string()
    .min(0)
    .max(30, 'Characters limit exceeded')
    .regex(REGEX.PHONE_NUMBER, 'Invalid phone number format')
    .optional()
    .or(z.literal('')),
  location: z.string().max(50, 'Characters limit exceeded'),
  organizationId: z
    .string()
    .min(1, 'Please select organisation')
    .max(50, 'Characters limit exceeded'),
  code: z
    .string()
    .min(2, 'Code is required')
    .max(50, 'Code cannot be longer than 50 characters'),
})

const patientValidationSchema = z.object({
  hasId: z.literal(false),
  id: z.string().optional(),
  firstName: z
    .string()
    .min(1, 'Required')
    .max(50, 'First name cannot be longer than 50 characters'),
  lastName: z
    .string()
    .min(1, 'Required')
    .max(50, 'Last name cannot be longer than 50 characters'),
  email: z
    .string()
    .min(1, 'Required')
    .max(50, 'Email cannot be longer than 50 characters'),
  password: z
    .string()
    .max(50, 'Password cannot be longer than 50 characters')
    .optional(),
  phoneNumber: z
    .string()
    .min(0)
    .max(30, 'Characters limit exceeded')
    .regex(REGEX.PHONE_NUMBER, 'Invalid phone number format')
    .optional()
    .or(z.literal('')),
  dob: z.string().max(100, 'Characters limit exceeded').min(1, 'Required'),
  height: z
    .number()
    .min(1, 'Please enter a valid height')
    .max(15, 'Please enter a valid height'),
  weight: z
    .number()
    .min(1, 'Please enter a valid weight')
    .max(2000, 'Please enter a valid weight'),
  physicianId: z.string().uuid('Please select physician'),
  technicianId: z.string().uuid('Please select technician'),
  paymentStatus: z.string().optional(),
  duration: z.number().min(1, 'Minimum 1 day is required'),
  startAt: z.string().min(1, 'Required'),
  location: z
    .string()
    .max(50, 'Location cannot be longer than 50 characters')
    .optional(),
  consent: z.literal(true, {
    errorMap: () => ({ message: 'You must agree to proceed.' }),
  }),
})

const schedulePatientValidationSchema = z.object({
  id: z.string().optional(),
  hasId: z.literal(true),
  physicianId: z.string().uuid('Please select physician'),
  technicianId: z.string().uuid('Please select technician'),
  duration: z.number().min(1, 'Minimum 1 day is required'),
  startAt: z.string().min(1, 'Required'),
})

const patientUnionSchema = z.discriminatedUnion('hasId', [
  patientValidationSchema,
  schedulePatientValidationSchema,
])

const addTemplate = z.object({
  templates: z
    .array(
      z.object({
        title: z.string().min(1, 'Required'),
        value: z.string(),
      }),
    )
    .min(1, 'Minimun 1 is required'),
})

export const VALIDATION_SCHEMA = {
  login,
  userValidationSchema,
  changePassword,
  addRole,
  addOrganisation,
  clinic,
  patientValidationSchema,
  addTemplate,
  patientUnionSchema,
}
