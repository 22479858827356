import { useSnapshot } from 'valtio'
import { IRoleActions, IRoleSlug } from 'src/interfaces'
import { SUPER_ADMIN } from 'src/constants'
import { authStore } from 'src/store'

enum FeatureEnum {
  'add' = 0,
  'read' = 1,
  'update' = 2,
  'delete' = 3,
}

type IReturnType = {
  isCreateAuthorized: boolean
  isReadAuthorized: boolean
  isUpdateAuthorized: boolean
  isDeleteAuthorized: boolean
}

/**
 *
 * @param route -> Type of role slugs
 * @returns -> IReturnType
 */

export const useUserGroupRole = (route: IRoleSlug): IReturnType => {
  const { userGroup, userGroupPermissions } = useSnapshot(authStore)

  const isSuperAdmin = userGroup.slug === SUPER_ADMIN

  const checkPermissionAuth = (action: IRoleActions = 'read') => {
    /**
     * Authorize permissisons to all routes if user group type is SUPER_ADMIN
     */
    if (isSuperAdmin) {
      if (action === 'read') return true
      if (
        route === 'admins' ||
        route === 'super_admin' ||
        // (route === 'staffs' && action !== 'delete')
        route === 'staffs'
      ) {
        return true
      }
      return false
    }

    /**
     * FIND role with current route
     */
    const role = userGroupPermissions?.find(
      userGroupPermission => userGroupPermission.role?.slug === route,
    )

    /**
     * Authorize if specified action is allowed for given route
     */
    if (role && role?.value?.charAt(FeatureEnum[action]) === '1') {
      return true
    }
    return false
  }

  return {
    isCreateAuthorized: checkPermissionAuth('add'),
    isReadAuthorized: checkPermissionAuth('read'),
    isUpdateAuthorized: checkPermissionAuth('update'),
    isDeleteAuthorized: checkPermissionAuth('delete'),
  }
}
