import { FC, Suspense, lazy } from 'react'
import { useSnapshot } from 'valtio'
import { authStore } from 'src/store'
import { Loader } from 'src/components'

const ChangePasswordModal = lazy(() =>
  import('./ChangePasswordModal').then(module => ({
    default: module.ChangePasswordModal,
  })),
)
const AddRoleModal = lazy(() =>
  import('./AddRoleModal').then(module => ({
    default: module.AddRoleModal,
  })),
)
const AddUserGroupModal = lazy(() =>
  import('./AddUserGroupModal').then(module => ({
    default: module.AddUserGroupModal,
  })),
)
const AddOrganisationModal = lazy(() =>
  import('./AddOrganisationModal').then(module => ({
    default: module.AddOrganisationModal,
  })),
)
const AddClinicModal = lazy(() =>
  import('./AddClinicModal').then(module => ({
    default: module.AddClinicModal,
  })),
)
const RoleAssignmentModal = lazy(() =>
  import('./RoleAssignmentModal').then(module => ({
    default: module.RoleAssignmentModal,
  })),
)
const LogOutModal = lazy(() =>
  import('./LogoutModal').then(module => ({
    default: module.LogOutModal,
  })),
)

/**
 * Global Modal Provider
 */
const ModalProvider: FC = () => {
  const { loggedIn } = useSnapshot(authStore)
  if (!loggedIn) return null
  return (
    <Suspense fallback={<Loader />}>
      <AddOrganisationModal />
      <RoleAssignmentModal />
      <ChangePasswordModal />
      <AddUserGroupModal />
      <AddClinicModal />
      <AddRoleModal />
      <LogOutModal />
    </Suspense>
  )
}

export { ModalProvider }
