import {
  MutationFunction,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query'
import { API } from '../lib'
import { IPatient, IPatientSchedule } from '../interfaces'
import { AxiosResponse } from 'axios'

const addPatient: MutationFunction<
  { data: IPatientSchedule },
  IPatientSchedule
> = async payload => {
  return API.post('/patient', payload)
}

const fetchPatients: QueryFunction<{
  patients: IPatient[]
  count: number
}> = async ({ queryKey }) => {
  const keyword = queryKey?.[3] as QueryKey
  const clinicId = queryKey?.[4] as QueryKey
  const status = queryKey?.[5] as QueryKey
  const page = queryKey?.[1] as QueryKey
  const pageSize = queryKey?.[2] as QueryKey

  const { data } = await API.get(`/patient/all`, {
    params: {
      page,
      pageSize,
      keyword,
      clinicId,
      status,
    },
  })
  return data
}

const schedulePatientAppointment: MutationFunction<
  AxiosResponse,
  IPatientSchedule
> = async payload => {
  return API.post(`/patient/${payload.id}/appointment`, payload)
}

export const patientService = {
  addPatient,
  fetchPatients,
  schedulePatientAppointment,
}
