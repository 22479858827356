import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'src/constants'
import { IClinic, IOrganization } from 'src/interfaces'
import { organisationService } from 'src/services'

/**
 * useOrganisation
 *
 * @param enabled => query enable/disabled boolean
 * @param searchQuery => search string
 * @returns useQuery response
 */
const useOrganisations = ({
  enabled,
  searchQuery,
}: {
  enabled: boolean
  searchQuery?: any
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_ORGANISATIONS, 1, 12, searchQuery],
    queryFn: organisationService.fetchOrganisations,
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response =>
      response?.map((organisation: IOrganization) => {
        return {
          key: organisation?.id,
          id: organisation?.id,
          email: organisation?.email,
          // code: organisation?.code,
          value: organisation?.id,
          name: organisation?.name,
          phoneNumber: organisation?.phoneNumber,
          location: organisation?.location,
          createdAt: organisation?.createdAt,
          disabled: organisation.disabled,
        }
      }),
  })
}

/**
 * useOrganisationClinics
 *
 * @param enabled => query enable/disabled boolean
 * @param searchQuery => search string
 * @returns useQuery response
 */
const useOrganisationClinics = ({
  enabled,
  searchQuery,
  organizationId,
}: {
  enabled: boolean
  searchQuery?: any
  organizationId?: string
}) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.FETCH_ORGANIZATION_CLINICS,
      1,
      12,
      searchQuery,
      organizationId,
    ],
    queryFn: organisationService.fetchOrganisationsClinic,
    enabled: enabled,
    refetchOnWindowFocus: false,
    retry: 1,
    select: response =>
      response?.map((clinic: IClinic) => {
        return {
          key: clinic?.id,
          id: clinic?.id,
          email: clinic?.email,
          code: clinic?.code,
          value: clinic?.id,
          name: clinic?.name,
          phoneNumber: clinic?.phoneNumber,
          location: clinic?.location,
          createdAt: clinic?.createdAt,
          disabled: clinic.disabled,
        }
      }),
  })
}

export { useOrganisations, useOrganisationClinics }
