import { FC } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { Button, Select, TextField } from 'src/components'
import { colors } from 'src/theme'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { VALIDATION_SCHEMA } from 'src/utils'

const MainWrapper = styled.div`
  padding: 1.5rem 2rem;
  padding-top: 0;
  background: ${colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid ${colors.lightGrey3};
  border-top: none;
  & .template {
    display: flex;
    gap: 1.25rem;
    margin-bottom: 0.85rem;
    & .text-field {
      flex: 2.5;
    }
    & .select-field {
      flex: 1;
    }
  }
  & .btn-add {
    background: ${colors.lightGrey3};
    padding: 16px;
    border-radius: 10px;
    border: 1px dashed ${colors.grey};
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin-top: 2rem;
    cursor: pointer;
  }
`

const ButtonWrapper = styled.div`
  float: right;
  margin-top: 1.5rem;
`

const observationOptions = [
  {
    id: 1,
    name: 'Interpretation',
    value: 'Interpretation',
  },
  {
    id: 2,
    name: 'Test Conditions',
    value: 'Test Conditions',
  },
  {
    id: 3,
    name: 'Indications',
    value: 'indications',
  },
  {
    id: 4,
    name: 'Signature',
    value: 'signature',
  },
]

const FreeFormTemplate: FC = () => {
  const navigate = useNavigate()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      templates: [
        {
          title:
            'Patient was scheduled for home sleep apnea testing for snoring, witnessed apnea and nocturia',
          value: undefined,
        },
        {
          title: 'Close f/u in sleep medicine clinic is recommended',
          value: undefined,
        },
        {
          title: 'Referral has been made for Oral Appliance therapy',
          value: undefined,
        },
        {
          title: 'Auto CPAP at 5-15cm H20 with a nasal mask is recommended',
          value: undefined,
        },
        {
          title: 'In tab PAP titration has been scheduled',
          value: undefined,
        },
        {
          title:
            'I have reviewed the raw data from all sleep epochs and agree with the findings of this study',
          value: undefined,
        },
        {
          title: 'Patient has been advised to not drive if sleepy',
          value: undefined,
        },
        {
          title: 'BMI is in the obese range. Weight loss has been discussed',
          value: undefined,
        },
        {
          title: 'John Doe, MD (Board Certified in Sleep Medicine)',
          value: undefined,
        },
        {
          title: 'BMI is in the obese range. Weight loss has been discussed',
          value: undefined,
        },
      ],
    },
    resolver: zodResolver(VALIDATION_SCHEMA.addTemplate),
    mode: 'all',
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'templates',
  })

  const onSubmit = () => {}

  return (
    <form>
      <MainWrapper>
        {fields.map((_, index) => (
          <div key={index} className="template">
            <Controller
              name={`templates.${index}.title`}
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  placeholder="Enter..."
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  className="text-field"
                />
              )}
            />

            <Controller
              name={`templates.${index}.value`}
              control={control}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
              }) => (
                <Select
                  required
                  placeholder="Observations"
                  value={value}
                  onChange={onChange}
                  options={observationOptions}
                  onBlur={onBlur}
                  error={error?.message}
                  className="select-field"
                />
              )}
            />
          </div>
        ))}
        <div
          onClick={() => {
            append({ title: '', value: undefined })
          }}
          className="btn-add"
        >
          + Add template
        </div>
      </MainWrapper>

      <ButtonWrapper className="btns-wrapper">
        <Button
          onClick={() => navigate('/patients')}
          label="Cancel"
          variant="link"
        />
        <Button
          onClick={e => {
            e.preventDefault()
            handleSubmit(onSubmit)()
          }}
          label="Save"
          htmlType="submit"
        />
      </ButtonWrapper>
    </form>
  )
}

export { FreeFormTemplate }
